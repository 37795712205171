import { css, keyframes } from '@amedia/brick-tokens';

// Animating the svg for icon: "pill-breaking"
const pulseBreaking = keyframes({
  '0%': {
    transform: 'scale(0.7)',
    boxShadow: '0 0 0 0 $$pulseColor70',
  },
  '70%': {
    transform: 'scale(1)',
    boxShadow: '0 0 0 8px $$pulseColor0',
  },
  '100%': {
    transform: 'scale(0.7)',
    boxShadow: '0 0 0 0 $$pulseColor0',
  },
});

export const iconVariants = {
  variants: {
    iconId: {
      'pill-breaking': {
        $$pulseColor70: '$colors$pillNonePulseStartBg',
        $$pulseColor0: '$colors$pillNonePulseEndBg',
        fill: '$pillNonePulseBg',
        borderRadius: '50%',
        animation: `${pulseBreaking} 2s infinite`,
        display: 'inline-flex',

        // a11y
        '@media (prefers-reduced-motion)': {
          animation: 'none',
        },
      },
      inverted: {
        true: {},
        false: {},
      },
    },
  },

  defaultVariants: {
    iconId: '',
  },
};

export const iconStyle: ({ iconId }) => string = css({
  display: 'inline-flex',
  ...iconVariants,
});

export const containerStyle = css({
  display: 'inline-flex',
});
