import { containerStyle } from './icon-styles';
import { brickIconTemplate } from './template';
import type { BrickIconData } from './types';
import { mapToTheme } from './utils';

export class BrickIcon extends HTMLElement {
  brickIconData: BrickIconData;

  constructor(brickIconData: BrickIconData) {
    super();
    this.brickIconData = brickIconData;
  }

  async connectedCallback() {
    this.setData();
    this.render();
  }

  setData() {
    this.brickIconData = {
      iconText: this.getAttribute('iconText') || '',
      iconTheme: mapToTheme(this.getAttribute('icontheme')),
      iconId: this.getAttribute('iconId') || '',
    };
    this.className = containerStyle();
  }

  render() {
    if (this.getAttribute('rendered') === 'true') {
      return;
    }

    this.innerHTML = this.template;
  }

  get template() {
    return brickIconTemplate(this.brickIconData);
  }
}

if (!customElements.get('brick-icon')) {
  customElements.define('brick-icon', BrickIcon);
}
