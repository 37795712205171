import type { BrickIconData } from './types';
import { containerStyle, iconStyle } from './icon-styles';
import { mapToTheme } from './utils';

export { containerStyle as iconContainerStyle, iconStyle };

// Check if we want to show or hide the icon from screenreaders
const ariaSr = (iconText) => {
  if (!iconText) {
    return 'aria-hidden="true"';
  }
  return 'role="graphics-symbol"';
};

export function brickIconTemplate(brickIconData: BrickIconData) {
  const { iconText, iconId, iconTheme } = brickIconData;
  const assetUrl = `/api/css-config/v1/brick`;

  const iconTxt = iconText
    ? `<title style="display: inline;">${iconText}</title>`
    : '';

  const asset =
    iconTheme && iconId
      ? `<use href="${assetUrl}/${iconTheme}/icons.svg#${iconId}" />`
      : '';

  return `
      <div class="svg-wrap ${iconStyle({ iconId })}">
        <svg ${ariaSr(iconText)} width="32" height="32">
          ${iconTxt}
          ${asset}
        </svg>
      </div>
      `;
}

export function renderBrickIcon(brickIconData: BrickIconData) {
  const iconTheme = mapToTheme(brickIconData.iconTheme);
  const html = brickIconTemplate({ ...brickIconData, iconTheme });
  return `<brick-icon rendered="true" iconid="${brickIconData.iconId}" class="${containerStyle}">${html}</brick-icon>`;
}
